import React, {useEffect, useState} from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';

import Waveform from 'waveform-react';
import { Box, Flex } from 'reflexbox';
import { WIDGET_SCREENS, WithWidget } from '../../models/widget/withWidget';
import { Text } from '../../ui/text/Text';
import { Button } from '../../ui/button/Button';
import { PoweredBy } from '../../ui/widget/PoweredBy';
import { Relative } from '../../ui/box/Relative';
import { RECORDER_STATES } from '../../../models/recorder/withRecorder';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../ui/input/Input';
import { WIDGET_INFORMATION_MAP } from '../../../models/widget/withWidget';
import { COLOR, MP4_BACKGROUND_STR, STATIC_BUCKET } from '../../../constants/common';
import { Toggle } from '../../ui/toggle/Toggle';
import { INTERACTION_API_TYPE_MAP } from '../../constants/common';
import {FlexCenter} from "../../ui/box/FlexCenter";
import {VoiceInteractionIcon} from "../../ui/icons/VoiceInteractionIcon";
import {TextInteractionIcon} from "../../ui/icons/TextInteractionIcon";
import { SendIcon } from '../../ui/icons/SendIcon';
import { GoBackIcon } from '../../ui/icons/GoBackIcon';
import { VoteInteractionIcon } from '../../ui/icons/VoteInteractionIcon';
import { VideoInteractionIcon } from '../../ui/icons/VideoInteractionIcon';
import { MainText } from '../../ui/widget/MainText';
import { GameInteractionIcon } from '../../ui/icons/GameInteractionIcon';
import {downloadAudioBuffer} from "../../../screens/messages/MessageScreen";
import { Video } from '../../features/video/Video';
import { WithVoteInteractionResults } from '../../models/interactions/withVoteInteractionResults';
import { useParams } from 'react-router-dom';
import { DotsIcon } from '../../ui/icons/DotsIcon';
import { Pointer } from '../../ui/box/Pointer';
import { CheckmarkIcon } from '../../ui/icons/CheckmarkIcon';
import { ChevronLeftIcon } from '../../ui/icons/ChevronLeftIcon';
import { LoaderIcon } from '../../ui/icons/LoaderIcon';

const Wrapper = styled(Relative)`
  width: 100vw;
  height: 100vh;
  background: #1a1a1a;
  display: flex;
  align-items: center;
  @media (min-width: 800px), (min-height: 1020px) {
    padding: 17px !important;
  }
`;

const GlobalStyle = createGlobalStyle`
   body {
    background: #1a1a1a !important;
  }
`;

const Content = styled(Relative)`
  background: ${({ background }) => background};
  margin: 16px auto !important;
  height: 100%;
  width: 100%;
  max-height: 844px;
  max-width: 474px;
  display: flex;
  flex-direction: column;
  padding: 25px 16px 60px;
  overflow: scroll;
  * {
    transition: opacity 350ms ease-in-out !important;
    opacity: ${({childsOpacity=1}) => childsOpacity};
  }
  @media (min-height: 844px), (min-width: 474px) {
    border-radius: 15px;
  }
  @media (min-height: 1020px) and (min-width: 800px), (min-width: 1020px), (min-height: 1200px) {
    padding: 20px;
  }
  
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

const WidgetButton = styled(Button)`
  position: relative;
  background: ${({ background }) => background};
  border: ${({borderWidth}) => borderWidth || '2px'} solid ${({borderColor}) => borderColor};
  ${({capitalize=false}) => capitalize && css`text-transform: capitalize;`}
  font-size: ${({ fontSize }) => fontSize || '17px'};
  font-weight: ${({ fontWeight }) => fontWeight || 600};
  letter-spacing: -.2px;
  ${({ height }) => css`height: ${height || '58px'};`};
  ${({ width }) => css`width: ${width || '100%'};`};
  ${({ disabled }) => disabled && css`pointer-events: none`};
  
  ${({ fading }) => fading ? css`
    animation: answer-fade-out .8s ease-in-out;
    animation-fill-mode: forwards;
    
    @keyframes answer-fade-out {
      30% {
        opacity: 0;
      }
      100% {
        height: 0;
        opacity: 0;
        margin: 0;
        padding: 0;
      }
    }
  ` : css`
    margin: ${({margin}) => margin || '16px 0 0'} !important;
  `}
  
  svg {
    height: ${({ iconSize }) => iconSize || '25px'};
    width: ${({ iconSize }) => iconSize};
    margin-right: 4px;
  } 
  
  @media(max-height: 760px) {
    margin-top: 5px !important;
  }
`

const ButtonStatistics = styled(Flex)`
  left: 0;
  border-radius: 0 5px 5px 0;
  background-color: ${({color}) => color};
  width: ${({value}) => value}%;
  height: 100%;
  position: absolute;
`

const ChoiceWrapper = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  font-weight: 600;
`

const TextButton = styled(Button)`
  background: none;
  margin: ${({margin}) => margin || '16px auto'} !important;
  width: fit-content;
  font-size: 16px;
  font-weight: ${({ fontWeight }) => fontWeight || 600};
  height: 40px;
  
  svg {
    height: ${({ iconSize }) => iconSize || '22px'};
    width: ${({ iconSize }) => iconSize};
    margin-right: 4px;
  }
`

const InteractionImage = styled.img`
  border-radius: 5px;
  width: 343px;
  height: 343px;
  max-height: 434px;
  object-fit: cover;
  margin: 15px auto;
  @media(max-height: 760px) {
    margin-bottom: 5px;
  }
  @media (min-height: 1020px) and (min-width: 800px), (min-width: 1020px), (min-height: 1200px) {
    margin-top: 55px;
  }
`;

const TopBar = styled(Flex)`
  align-items: center;
   svg {
    height: 23px;
   }
`

const ChevronWrapper = styled(Pointer)`
  height: 40px;
  width: 40px;
  margin-left: -8px !important;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ChannelImage = styled.img`
  width: 42px;
  height: 42px;
  object-fit: contain;
  @media (min-width: 800px), (min-height: 1020px) {
    position: fixed;
    top: 27px;
    left: 27px;
  }
  @media (min-height: 1020px) and (min-width: 800px), (min-width: 1020px), (min-height: 1200px) {
    top: 39px;
    left: 49px;
    width: 75px;
    height: 75px;
  }
`;

const InteractionVideo = styled(Video)`
  width: 100%;
  height: calc(100vw - 40px);
  max-height: 434px;
  object-fit: cover;
  margin: 15px 0;
  border-radius: 5px;
  @media(max-height: 760px) {
    margin-bottom: 5px;
  }
  @media (min-height: 1020px) and (min-width: 800px), (min-width: 1020px), (min-height: 1200px) {
    margin-top: 55px;
  }
`;

const UploadInsideButton = styled.input`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
`;

const TextBolded = styled(Text)`
  font-size: 12px;
  font-weight: 600;
  color: ${({color}) => color};
`

const WaveContainer = styled(Box)`
  width: 100%;
  overflow: hidden;
  padding: 7px 0;
  max-height: 87.5px;
`;

const DotsWrapper = styled.div`
  border: 1px solid black;
  border-radius: 5px;
  height: fit-content;
  display: inline-flex;
  margin: 0 17px;
  svg {
    height: 35px;
  }
  ${({ rotate }) =>
    rotate && css`
      transform: rotate(${rotate}deg);
      margin-left: 22px;
    `
  }
`

const BottomSection = styled.div`
  z-index: 1;
  ${({disable}) => disable || css`
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 32px);
    margin-left: -16px;
    background-color: #f3f3f3;
    padding: 0 16px 48px;
    ${({height}) => height && css`height: ${height};`}
  `}
`

const RecordButtonWrapper = styled.div`
  margin: 16px 0 32px;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  border: solid 2px #242424;
  cursor: pointer;
  position: relative;
`

const RecordButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  
  transition: all 100ms ease-in-out !important;
  ${({state}) =>
    state === RECORDER_STATES.recording ? css`
      background-color: #f82d2d;
      border-radius: 10%;
      width: 50%;
      height: 50%;
    ` : state === RECORDER_STATES.finished ? css`
      border-radius: 0;
      width: 36px;
      height: 36px;
      box-sizing: border-box;
      border-style: solid;
      border-width: 18px 10px 18px 29px;
      border-color: transparent transparent transparent #f82d2d;
      transform: translate(-30%, -50%);
      ${({playing}) => playing && css`
        border-style: double;
        border-width: 0 0 0 25px;
        height: 30px;
        transform: translate(-34%, -50%);
      `};
    ` : css`
      border-radius: 50%;
      background-color: #f82d2d;
    `};
`

const AnimateLoader = styled.div`
  display: flex;
  align-items: center;
  
  svg {
    animation:spin 1.5s linear infinite;
    margin-left: 4px;
    height: 26px;
    width: 26px;
  }

  @keyframes spin {
    100% { transform:rotate(360deg); }
  }
`

export const WidgetScreen = () => {
  const {
    activeSegment,
    channel,
    background,
    activeScreen,
    screenFading,
    recordingState,
    extraUserInformation,
    showValidation,
    audioError,
    rememberMe,
    setRememberMe,
    setTextAnswer,
    textAnswer,
    shouldFetchResults,
    selectedChoices,
    changeActiveScreen,
    handleTestimonial,
    testimonialAccepted,
    startRecording,
    stopRecording,
    handleSend,
    remakeAudio,
    getPercentageProgress,
    getRecordingTime,
    getAudio,
    playing,
    playPause,
    setField,
    handleUploadFromFile,
    manageChoice,
    shouldGatherInfo,
    isFacebook,
    gatherInfoErrors,
    validateExtraInformation,
    setRecordingStateVideo,
  } = WithWidget();
  const { segmentId: interactionId } = useParams();
    const { results: choicesWithStats } = WithVoteInteractionResults({
    interactionId: shouldFetchResults ? interactionId : undefined,
  });
  const { t } = useTranslation();
  const audio = getAudio();
  const [audioBuffer, setAudioBuffer] = useState(null);

  useEffect(() => {
    if (audio) {
      downloadAudioBuffer(audio, setAudioBuffer);
    }
  }, [audio]);

  if (!channel || !activeSegment) {
    return null;
  }

  const { customisation } = activeSegment;

  const textExcludeScreens = [
    WIDGET_SCREENS.videoUploaded,
  ];

  const logoExcludeScreens = [
    WIDGET_SCREENS.gatherInformation,
    WIDGET_SCREENS.voting,
    WIDGET_SCREENS.videoUploaded,
    WIDGET_SCREENS.voting,
    WIDGET_SCREENS.write,
    WIDGET_SCREENS.results,
    WIDGET_SCREENS.thanks,
    WIDGET_SCREENS.sending,
  ];

  const ifGatherInfo = activeSegment.informationToGather && !testimonialAccepted

  const handleRecordButtonClick = () => {
    if(recordingState === RECORDER_STATES.waiting) startRecording()
    else if (recordingState === RECORDER_STATES.recording) stopRecording()
    else if (recordingState === RECORDER_STATES.finished) playPause()
  }

  const getRecorderButtonText = () => {
    if (recordingState === RECORDER_STATES.finished) {
      return t('Play recording');
    }
    if (recordingState === RECORDER_STATES.recording) {
      return t('Stop recording');
    }
    return t('Start recording');
  }

  const showGatherInfoForm = activeSegment.gather_information && [
      WIDGET_SCREENS.gatherInformation,
      WIDGET_SCREENS.thanks,
      WIDGET_SCREENS.sending
    ].includes(activeScreen)

  const showThanksInBottomSection =
    activeSegment.type === INTERACTION_API_TYPE_MAP.voice &&
    !activeSegment.gather_information;

  return (
    <Wrapper>
      <GlobalStyle />
      {isFacebook ? (
        <Content background={'#edecec'}>
          <FlexCenter fullHeight>
            <Text size={'h5'} center theme={'dark'}>
              Unfortunately, <b>Facebook</b> doesn't allow<br/>
              you to live the full experience!
              <br/><br/>
              To participate you must click on
              <br/><br/>
              <FlexCenter>
                <DotsWrapper>
                  <DotsIcon/>
                </DotsWrapper>
                ou
                <DotsWrapper rotate={'90'}>
                  <DotsIcon/>
                </DotsWrapper>
              </FlexCenter>
              <br/>
              And click on:
              <br/><br/>
              "Open in a browser"
              <br/><br/>
              <b>Thank you</b>
            </Text>
          </FlexCenter>
        </Content>
      ) : (
        <Content background={customisation.main_bg} childsOpacity={screenFading ? 0 : 1}>
          <TopBar>
            {activeScreen !== WIDGET_SCREENS.init && (
              <ChevronWrapper
                inactive={[
                  WIDGET_SCREENS.gatherInformation,
                  WIDGET_SCREENS.thanks,
                  WIDGET_SCREENS.sending
                ].includes(activeScreen)}
                onClick={() => changeActiveScreen(WIDGET_SCREENS.init)}
              >
                <ChevronLeftIcon color={[
                  WIDGET_SCREENS.gatherInformation,
                  WIDGET_SCREENS.thanks,
                  WIDGET_SCREENS.sending
                ].includes(activeScreen) ? COLOR.grey : COLOR.black}/>
              </ChevronWrapper>
            )}
            <ChannelImage src={channel.logo_url} />
          </TopBar>
          {!logoExcludeScreens.includes(activeScreen) && (activeScreen === WIDGET_SCREENS.init) ? (
            <>
              {background && background.includes(MP4_BACKGROUND_STR) && !background.includes(STATIC_BUCKET) ? (
                <InteractionVideo src={background} autoPlay loop controls muted playsInline id={'InteractionVideo'} onPlay={() => {
                  window.document.addEventListener('click', () => {
                    const video = document.getElementById('InteractionVideo');
                    if (video) {
                      video.muted = false;
                    }
                  })
                }} />
              ) : (
                <InteractionImage src={background} />
              )}
            </>
          ) : (
            <Box marginTop={'23px'}/>
          )}

          {!textExcludeScreens.includes(activeScreen) && (
            <MainText
              textAlign={showGatherInfoForm ? 'left' : 'center'}
              margin={showGatherInfoForm ? '9px 8px 3px' : '9px 8px 16px'}
              color={customisation.main_text}
            >
              { showGatherInfoForm ? customisation.text_personal : activeSegment.text}
            </MainText>
          )}

          {activeScreen === WIDGET_SCREENS.init && (
            <Box>
              {activeSegment.type === INTERACTION_API_TYPE_MAP.voice && (
                <>
                  <WidgetButton onClick={startRecording}
                    background={customisation.btn_1_color}
                    color={customisation.btn_1_text}
                    borderColor={customisation.btn_1_border}
                  >
                    <VoiceInteractionIcon color={customisation.btn_1_text}/>
                    {t('Participate vocally')}
                  </WidgetButton>
                  {activeSegment.enable_written_answer && (
                    <TextButton color={customisation.main_text} onClick={() => changeActiveScreen(WIDGET_SCREENS.write)}>
                      <TextInteractionIcon color={customisation.main_text}/>
                      {t('Participate in writing')}
                    </TextButton>
                  )}
                </>
              )}
              {activeSegment.type === INTERACTION_API_TYPE_MAP.video && (
                <>
                  <WidgetButton
                    background={customisation.btn_1_color}
                    color={customisation.btn_1_text}
                    borderColor={customisation.btn_1_border}
                  >
                    <UploadInsideButton
                      id={'gallery-upload'}
                      type={'file'}
                      accept={'video/*'}
                      onChange={handleUploadFromFile}
                    />
                    <VideoInteractionIcon color={customisation.btn_1_text}/>
                    {t('Participate in video')}
                  </WidgetButton>
                  <FlexCenter>
                    {activeSegment.enable_audio_answer && (
                      <TextButton color={customisation.main_text} onClick={() => changeActiveScreen(WIDGET_SCREENS.recorder)}>
                        <VoiceInteractionIcon color={customisation.main_text}/>
                        {t('In voice')}
                      </TextButton>
                    )}
                    {activeSegment.enable_written_answer && activeSegment.enable_audio_answer && (
                      <p style={{color: customisation.main_text}}>|</p>
                    )}
                    {activeSegment.enable_written_answer && (
                      <TextButton color={customisation.main_text} onClick={() => changeActiveScreen(WIDGET_SCREENS.write)}>
                        <TextInteractionIcon color={customisation.main_text}/>
                        {t('Writing')}
                      </TextButton>
                    )}
                  </FlexCenter>
                </>
              )}
              {activeSegment.type === INTERACTION_API_TYPE_MAP.vote && (
                <WidgetButton
                  background={customisation.btn_1_color}
                  color={customisation.btn_1_text}
                  borderColor={customisation.btn_1_border}
                  onClick={() => changeActiveScreen(WIDGET_SCREENS.voting)}
                >
                  <VoteInteractionIcon color={customisation.btn_1_text}/>
                  {t('Participate in the survey')}
                </WidgetButton>
              )}
              {activeSegment.type === INTERACTION_API_TYPE_MAP.text && (
                <WidgetButton
                  background={customisation.btn_1_color}
                  color={customisation.btn_1_text}
                  borderColor={customisation.btn_1_border}
                  onClick={() => changeActiveScreen(WIDGET_SCREENS.write)}
                >
                  <TextInteractionIcon color={customisation.btn_1_text}/>
                  {t('Participate in writing')}
                </WidgetButton>
              )}
              {activeSegment.type === INTERACTION_API_TYPE_MAP.game && (
                <WidgetButton
                  background={customisation.btn_1_color}
                  color={customisation.btn_1_text}
                  borderColor={customisation.btn_1_border}
                  onClick={() => changeActiveScreen(
                    activeSegment.game_type === 'registration' ? WIDGET_SCREENS.gatherInformation : WIDGET_SCREENS.voting
                  )}
                >
                  <GameInteractionIcon color={customisation.btn_1_text}/>
                  {t('Participate in game')}
                </WidgetButton>
              )}
            </Box>
          )}

          {activeScreen === WIDGET_SCREENS.recorder && (

            <>
              <BottomSection>
                {audioBuffer && (recordingState === RECORDER_STATES.finished || recordingState === RECORDER_STATES.recording) ? (
                  <WaveContainer>
                    <Waveform
                      key={audio?.src}
                      // Audio buffer
                      buffer={audioBuffer}
                      // waveform height
                      height={60}
                      markerStyle={{
                        // Position marker color
                        color: customisation.sound_color || COLOR.link,
                        // Position marker width (in pixels)
                        width: 1,
                      }}
                      // Wave plot type (line or bar)
                      plot="line"
                      // Marker position on waveform (0 - 1)
                      position={getPercentageProgress()}
                      // redraw waveform on window size change (default: true)
                      responsive
                      // Show position marker
                      showPosition={recordingState === RECORDER_STATES.finished}
                      waveStyle={{
                        // animate waveform on draw (default: true)
                        animate: true,
                        // waveform color
                        color: customisation.btn_1_color,
                        // width of each rendered point (min: 1, max: 10)
                        pointWidth: 1,
                      }}
                      // waveform width
                      width={400}
                    />
                  </WaveContainer>
                ) : (<Box marginTop={'90px'}/>)}
                <Text fontWeight={'300'} center size={'h5'} color={'#191919'} capital>
                  {recordingState === RECORDER_STATES.waiting ?
                    activeSegment.max_recording_time ? `${t('Recording time limit')}: ${activeSegment.max_recording_time} sec` : ''
                    : getRecordingTime(false)
                  }
                </Text>
                <Text fontWeight={'600'} color={'#191919'} size={'h5'} semiBold>
                  {getRecorderButtonText()}
                </Text>
                <RecordButtonWrapper onClick={handleRecordButtonClick}>
                  <RecordButton state={recordingState} playing={playing}/>
                </RecordButtonWrapper>
                {recordingState === RECORDER_STATES.finished && (
                  <Flex flexDirection={'column'} width={'100%'}>
                    <WidgetButton
                      margin={'0'}
                      onClick={() => shouldGatherInfo()}
                      background={customisation.btn_1_color}
                      color={customisation.btn_1_text}
                      borderColor={customisation.btn_1_border}
                    >
                      {!ifGatherInfo && (
                        <SendIcon color={customisation.btn_1_text}/>
                      )}
                      {t(ifGatherInfo ? 'Continue' : 'Send my participation')}
                    </WidgetButton>
                    <TextButton
                      onClick={remakeAudio}
                      background={customisation.btn_2_color}
                      color={'#191919'}
                      borderColor={customisation.btn_2_border}
                      fontWeight={500}
                    >
                      <GoBackIcon color={'#191919'}/>
                      {t('Start over')}
                    </TextButton>
                  </Flex>
                )}
              </BottomSection>

              {audioError && (
                <Text center mt={'20px'} bold color={customisation.main_text} capital>
                  {t(audioError)}
                </Text>
              )}
            </>
          )}

          {activeScreen === WIDGET_SCREENS.videoUploaded && (
            <>
              <Video id={'video_recording'} autoPlay muted loop />
              <Flex flexDirection={'column'}>
                <WidgetButton
                  onClick={() => shouldGatherInfo()}
                  background={customisation.btn_1_color}
                  color={customisation.btn_1_text}
                  borderColor={customisation.btn_1_border}
                >
                  {!ifGatherInfo && (
                    <SendIcon color={customisation.btn_1_text}/>
                  )}
                  {t(ifGatherInfo ? 'Continue' : 'Send my participation')}
                </WidgetButton>
                <Box marginRight={'10px'}/>
                <TextButton
                  onClick={() => {
                    changeActiveScreen(WIDGET_SCREENS.init)
                    setRecordingStateVideo(RECORDER_STATES.waiting);
                  }}
                  background={customisation.btn_2_color}
                  color={customisation.btn_2_text}
                  borderColor={customisation.btn_2_border}
                  fontWeight={500}
                >
                  <GoBackIcon color={customisation.btn_2_text}/>
                  {t('Start over')}
                </TextButton>
              </Flex>
            </>
          )}

          {activeScreen === WIDGET_SCREENS.write && (
            <Box mt={'10px'}>
              <Input
                color={customisation.btn_1_text}
                background={'#44444488'}
                widgetInputSmall
                value={textAnswer}
                textArea
                onChange={(name, value) => setTextAnswer(value)}
                placeholder={'Ecrivez ici…'}
              />
              <WidgetButton
                margin={'20px 0 0'}
                flex={1}
                onClick={() => shouldGatherInfo()}
                background={customisation.btn_1_color}
                color={customisation.btn_1_text}
                borderColor={customisation.btn_1_border}
              >
                {!ifGatherInfo && (
                  <SendIcon color={customisation.btn_1_text}/>
                )}
                {t(ifGatherInfo ? 'Continue' : 'Send my participation')}
              </WidgetButton>
            </Box>
          )}

          {activeScreen === WIDGET_SCREENS.voting && (
            <Box>
              {activeSegment.choicesFull.map((choice) => (
                <WidgetButton
                  margin={'16px 0 0'}
                  key={`choice-${choice.id}`}
                  color={(selectedChoices[choice.id] ? customisation.main_bg : customisation.main_text) + 'CC'}
                  borderColor={customisation.main_text}
                  background={selectedChoices[choice.id] ? customisation.main_text : 'none'}
                  onClick={() => manageChoice(choice)}
                  height={'47px'}
                  borderWidth={'2px'}
                >
                  {choice.label || ''}
                </WidgetButton>
              ))}
              {Object.keys(selectedChoices).length ? (
                <WidgetButton
                  flex={1}
                  onClick={() => shouldGatherInfo()}
                  background={customisation.btn_1_color}
                  color={customisation.btn_1_text}
                  borderColor={customisation.btn_1_border}
                  margin={'32px 0 0'}
                >
                  {!ifGatherInfo && (
                    <SendIcon color={customisation.btn_1_text}/>
                  )}
                  {t(ifGatherInfo ? 'Continue' : 'Send my participation')}
                </WidgetButton>
              ) : null}
            </Box>
          )}

          {showGatherInfoForm && (
            <Box>
              <Box margin={'29px 0'}>
                {Object.entries(activeSegment.information_to_gather).map(
                  ([fieldName, fieldProps]) => fieldProps.active && (
                    <Box key={fieldName} margin={'20px 0 10px'}>
                      <TextBolded>
                        <Input
                          rounded
                          borderless
                          padding={'16px 36px 16px 12px'}
                          background={customisation.btn_1_color + 'B3'}
                          color={customisation.btn_1_text}
                          placeholderColor={customisation.btn_1_text + 'CC'}
                          fontWeight={600}
                          fontFamily={'Montserrat'}
                          placeholder={t(WIDGET_INFORMATION_MAP[fieldName])}
                          widgetInputSmall
                          value={extraUserInformation[fieldName]}
                          onChange={(name, val) => {
                            if(WIDGET_SCREENS.gatherInformation === activeScreen) {
                              setField(name, val)
                              validateExtraInformation(false)
                            }
                          }}
                          name={fieldName}
                          valid={!gatherInfoErrors[fieldName]}
                          showValidation={showValidation}
                          required={fieldProps.mandatory}
                        />
                      </TextBolded>
                    </Box>
                  )
                )}
                <Flex justifyContent={'space-between'} alignItems={'center'}>
                  <MainText color={customisation.main_text}>{t('Memorise my information')}</MainText>
                  <Toggle
                    color={customisation.btn_1_color}
                    active={rememberMe}
                    onChange={(val) => {
                      if(WIDGET_SCREENS.gatherInformation === activeScreen) setRememberMe(val)
                    }}
                  />
                </Flex>
              </Box>
              {activeScreen === WIDGET_SCREENS.gatherInformation && (
                <>
                  <WidgetButton
                    margin={'0'}
                    background={customisation.btn_1_color}
                    color={customisation.btn_1_text}
                    borderColor={customisation.btn_1_border}
                    onClick={handleSend}
                  >
                    <SendIcon color={customisation.btn_1_text}/>
                    {t('Send my participation')}
                  </WidgetButton>
                  {!!Object.keys(gatherInfoErrors).length && showValidation && (
                    <WidgetButton
                      capitalize={false}
                      height={'fit-content'}
                      background={'#db3838'}
                      color={'#FFFFFF'}
                      borderColor={'#db3838'}
                      fontWeight={500}
                      fontSize={'14px'}
                      disabled
                    >
                      <Flex flexDirection={'column'}>
                        <Text mb={'8px'} semiBold theme={'white'}>Error(s) in the form</Text>
                        {Object.values(gatherInfoErrors).map(val => (
                          <Text theme={'white'}>{val}</Text>
                        ))}
                      </Flex>
                    </WidgetButton>
                  )}
                </>
              )}
            </Box>
          )}

          {[WIDGET_SCREENS.thanks, WIDGET_SCREENS.sending].includes(activeScreen) && (
            <BottomSection
              disable={!showThanksInBottomSection}
              height={'358px'}
            >
              <Flex
                flexDirection={'column'}
                justifyContent={showThanksInBottomSection && 'center'}
                height={showThanksInBottomSection && '100%'}
                width={showThanksInBottomSection && '100%'}
              >
                {(showGatherInfoForm ? Object.values(selectedChoices) : activeSegment.choicesFull).map((choice) => (
                  <WidgetButton
                    margin={'0 0 16px'}
                    fading={!selectedChoices[choice.id]}
                    key={`choice-${choice.id}`}
                    color={(selectedChoices[choice.id] ? customisation.main_bg : customisation.main_text) + 'CC'}
                    borderColor={customisation.main_text}
                    background={selectedChoices[choice.id] ? customisation.main_text : 'none'}
                    height={'47px'}
                    borderWidth={'2px'}
                    disabled
                  >
                    {choice.label || ''}
                  </WidgetButton>
                ))}

                <WidgetButton
                  margin={showGatherInfoForm ? '0' : '16px 0 0'}
                  background={customisation.btn_1_color + (WIDGET_SCREENS.sending === activeScreen ? 'CC' : '99')}
                  color={customisation.btn_1_text}
                  borderColor={customisation.btn_1_border + (WIDGET_SCREENS.sending === activeScreen ? 'CC' : '99')}
                  disabled
                >
                  {WIDGET_SCREENS.sending === activeScreen ? (
                    <AnimateLoader>
                      {t('Sending in progress')}
                      <LoaderIcon color={customisation.btn_1_text}/>
                    </AnimateLoader>
                  ) : (
                    <>
                      {t('Answer sent')}
                      <CheckmarkIcon color={customisation.btn_1_text}/>
                    </>
                  )}
                </WidgetButton>

                {WIDGET_SCREENS.thanks === activeScreen && (
                  <>
                    <WidgetButton
                      background={'#31CA68'}
                      color={'#FFFFFF'}
                      borderColor={'#31CA68'}
                      fontWeight={500}
                      fontSize={'14px'}
                      disabled
                    >
                      {customisation.text_thanks}
                    </WidgetButton>

                    {activeSegment.testimonial_demanded && !testimonialAccepted ? (
                      <>
                        <Text
                          color={customisation.main_text}
                          center
                          marginTop={'25px'}
                          upper
                        >
                          {activeSegment.testimonial}
                        </Text>
                        <WidgetButton
                          width={'100%'}
                          onClick={handleTestimonial}
                          background={customisation.btn_1_color}
                          color={customisation.btn_1_text}
                          borderColor={customisation.btn_1_border}
                        >
                          <VoiceInteractionIcon color={customisation.btn_1_text} />
                          {t('Record voice message')}
                        </WidgetButton>
                      </>
                    ) : activeSegment.type === INTERACTION_API_TYPE_MAP.vote && (
                      <Pointer
                        hoverUnderline
                        margin={'32px auto 0'}
                        color={customisation.main_text}
                        onClick={() => changeActiveScreen(WIDGET_SCREENS.results)}
                      >
                        {t('See the results')}
                      </Pointer>
                    )}
                  </>
                )}
              </Flex>
            </BottomSection>
          )}

          {activeScreen === WIDGET_SCREENS.results && (
            <Box>
              {choicesWithStats.map((choice) => (
                <WidgetButton
                  margin={'16px 0 0'}
                  key={`result-${choice.id}`}
                  color={customisation.main_text + 'CC'}
                  borderColor={customisation.main_text}
                  background={'none'}
                  height={'47px'}
                  borderWidth={'2px'}
                >
                  <ButtonStatistics value={choice.percentage} color={customisation.main_text + 'CC'} />
                  <ChoiceWrapper>
                    <span>{choice.label || ''}</span>
                    <span>{choice.percentage}%</span>
                  </ChoiceWrapper>
                </WidgetButton>
              ))}
            </Box>
          )}
          {channel.show_voxm_logo && (
            <PoweredBy color={customisation.main_text} />
          )}
        </Content>
      )}
    </Wrapper>
  );
}
