import { useEffect, useState } from 'react';
import { API_ENDPOINTS, isMobile } from '../../constants/common';
import { request } from '../utils/request';
import { useHistory } from 'react-router-dom';
import { useUser } from './withUser';
import { WithLogin } from './withLogin';
import { WithBackup } from '../backup/withBackup';

export const userStatuses = [
  { value: true, label: 'Active' },
  { value: false, label: 'Disabled' },
];

export const userLanguages = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'French' },
  { value: 'pt', label: 'Portuguese' },
];

export const userReceiveEmails = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export const userRoles = [
  { value: 'administrator', label: 'Admin media' },
  { value: 'super_administrator', label: 'Admin VoxM' },
];

const defaultUser = { active: true, language: 'fr' };
const voxmChannel = { value: null, label: 'BE VoxM' };

export const WithBEUser = ({ id: idDefault, onSave }) => {
  const [user, setUser] = useState(defaultUser);
  let [id, setId] = useState();
  const [channelOptions, setChannelOptions] = useState([voxmChannel]);
  const { user: ownUser, setLanguage } = useUser();
  const { logout } = WithLogin();
  const history = useHistory();
  const { setBackup, handleBackBackup, showConfirm } = WithBackup(defaultUser);

  const setField = (name, value) => setUser({ ...user, [name]: value });

  const fetchChannels = async () => {
    const { data } = await request({
      url: API_ENDPOINTS.channelList,
      method: 'GET',
    });

    const newChannelOptions = data.map(channel => ({
      value: channel.id,
      label: channel.label,
    }));
    newChannelOptions.unshift(voxmChannel);
    setChannelOptions(newChannelOptions);
  };

  const userCreate = async () => {
    const { data } = await request({
      url: API_ENDPOINTS.usersNew,
      method: 'POST',
      body: user,
    });
    if (data.id) {
      id = data.id;
      setId(data.id);
      await userImageSave();
      if (isMobile) {
        history.replace(`/users/${data.id}`);
      } else {
        fetchUser();
        onSave && onSave();
      }
    }
  };

  const userInfoSave = async () => {
    const { data } = await request({
      url: API_ENDPOINTS.user(id),
      method: 'PUT',
      body: user,
    });
    setUser(data);
    setBackup(data);
    if (data.id === ownUser.id) {
      setLanguage(data.language);
    }
    onSave && onSave();
  };

  const userImageSave = async () => {
    if (user.image) {
      const formData = new FormData();
      formData.append('image', user.image);
      const { data } = await request({
        url: API_ENDPOINTS.userImageUpload(id),
        method: 'POST',
        formData,
      });
      setUser(data);
      setBackup(data);
    } else if (user.image === false) {
      const { data } = await request({
        url: API_ENDPOINTS.userImageUpload(id),
        method: 'DELETE',
      });
      setUser(data);
      setBackup(data);
    }
  };

  const resetPassword = async () => {
    const body = {
      email: user.login,
    };
    await request({
      url: API_ENDPOINTS.usersReset,
      method: 'POST',
      body,
    });
    if (id == ownUser.id) {
      logout(`?email=${ownUser.login}`);
    }
  };

  const saveUser = async () => {
    if (id === 'new') {
      userCreate();
    } else {
      userInfoSave();
      userImageSave();
    }
  };

  const fetchUser = async forceId => {
    const { data } = await request({
      url: API_ENDPOINTS.user(forceId || id || idDefault),
    });
    setUser(data);
    setBackup(data);
  };

  useEffect(() => {
    setId(idDefault);
    if (idDefault !== 'new') {
      fetchUser(idDefault);
    } else {
      setUser(defaultUser);
      setBackup(defaultUser);
    }
  }, [idDefault]);

  useEffect(() => {
    fetchChannels();
  }, []);

  return {
    user,
    handleBackBackup,
    channelOptions,
    showConfirm,
    setField,
    saveUser,
    userImageSave,
    resetPassword,
  };
};
