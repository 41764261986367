import { useEffect, useRef, useState } from 'react';
import i18n from 'i18next';

let listeningTimerInterval = null;

export const RECORDER_STATES = {
  waiting: 'waiting',
  started: 'started',
  recording: 'recording',
  finished: 'finished',
};

export const WithRecorder = ({ onSend, onError }) => {
  const [recordingState, setRecordingState] = useState(RECORDER_STATES.waiting);
  const [recordingTime, setRecordingTime] = useState(0);
  const recordingTimeRef = useRef(recordingTime);
  recordingTimeRef.current = recordingTime;
  let [lisetningTime, setListeningTime] = useState(0);
  const [uploading, setUploading] = useState(false);
  let [playing, setPlaying] = useState(false);
  let [audio, setAudio] = useState();
  let [mediaRecorder, setMediaRecorder] = useState();
  let [recordingTimerInterval, setRecordingTimerInterval] = useState();
  let [audioBlob, setAudioBlob] = useState();
  let [audioUrl, setAudioUrl] = useState();
  // Handle Safari not working
  const [isSafari, setIsSafari] = useState();
  const [maxRecordingTime, setMaxRecordingTime] = useState(0);
  const maxRecordingTimeRef = useRef(maxRecordingTime);
  maxRecordingTimeRef.current = maxRecordingTime;

  const initFromUrl = (url, initDuration) => {
    setAudioUrl(url);
    audio = new Audio(url);
    audio.currentTime = 0;
    audio.addEventListener('ended', () => {
      stop();
    });
    if (initDuration) {
      setRecordingTime(initDuration);
    }
    audio.addEventListener('durationchange', () => {
      const { duration } = audio;
      // It's Infinity on Safari
      if (duration && duration !== Infinity) {
        setRecordingTime(Math.round(duration * 100));
      } else if (duration === Infinity) {
        setIsSafari([url, initDuration]);
      }
    });
    setAudio(audio);
    setRecordingState(RECORDER_STATES.finished);
  };

  const startRecording = (maxRecordingTimeInput) => {
    if (maxRecordingTimeInput && parseInt(maxRecordingTimeInput) > 0) {
      maxRecordingTimeInput = parseInt(maxRecordingTimeInput);
      setMaxRecordingTime(maxRecordingTimeInput * 100);
    }
    setRecordingState(RECORDER_STATES.started);
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorder = new MediaRecorder(stream);
        setMediaRecorder(mediaRecorder);

        const audioChunks = [];
        mediaRecorder.addEventListener('dataavailable', (event) => {
          if (event.data) {
            audioChunks.push(event.data);
            audioBlob = new Blob(audioChunks);
            if (audioChunks[0].type && audioBlob.type === '') {
              audioBlob = new Blob(audioChunks, { type: audioChunks[0].type });
            } else if (audioBlob.type === '') {
              audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
            }
            setAudio(audioBlob);
            audioUrl = URL.createObjectURL(audioBlob);
            setAudioUrl(audioUrl);
          }
        });

        mediaRecorder.addEventListener('stop', () => {
          audioBlob = new Blob(audioChunks);
          if (audioChunks[0].type && audioBlob.type === '') {
            audioBlob = new Blob(audioChunks, { type: audioChunks[0].type });
          }
          else if (audioBlob.type === '') {
            audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
          }
          setAudioBlob(audioBlob);
          audioUrl = URL.createObjectURL(audioBlob);
          setAudioUrl(audioUrl);
          audio = new Audio(audioUrl);
          audio.addEventListener('ended', () => {
            stop();
          });
          if (
            maxRecordingTimeRef.current &&
            ((maxRecordingTimeRef.current / 100) - (recordingTimeRef.current / 100)) < -1
          ) {
            onError && onError(i18n.t('Recorded participation is too long. Please try again.'));
          }
          setAudio(audio);
        });

        mediaRecorder.start(5000); // Let's receive 5 second blobs
        setRecordingState(RECORDER_STATES.recording);
        recordingTimerInterval = setInterval(() => {
          if (
            maxRecordingTimeRef.current &&
            recordingTimeRef.current > maxRecordingTimeRef.current
          ) {
            stopRecording();
          } else {
            setRecordingTime((prevTime) => prevTime + 3);
          }
        }, 30);
        setRecordingTimerInterval(recordingTimerInterval);
      })
      .catch((e) => {
        console.error(e);
        resetAudio();
        onError && onError();
      });
  };

  const stopRecording = () => {
    // button click function
    setRecordingState(RECORDER_STATES.finished);
    mediaRecorder.stop();
    setTimeout(() => {
      clearInterval(recordingTimerInterval);
    }, 0);
  };

  const stop = () => {
    if (audio) {
      audio.pause();
      clearInterval(listeningTimerInterval);
      setRecordingState(RECORDER_STATES.finished);
      setListeningTime(0);
      setPlaying(false);
    }
    if (isSafari) {
      initFromUrl(...isSafari);
    }
  };

  const remake = () => {
    // button click function
    if (uploading) {
      return;
    }
    stop();
    resetAudio();
    startRecording(maxRecordingTime / 100);
  };

  const send = async () => {
    if (uploading || !audioBlob) {
      return;
    }
    setUploading(true);
    const file = new File([audioBlob], 'recording.webm', {
      type: 'video/webm',
    });
    if (onSend) {
      onSend(file);
      setUploading(false);
    }
  };

  const getRecordingTime = (long = true) => {
    const recordingMs = recordingTime % 100;
    const seconds = parseInt(recordingTime / 100);
    const recordingSeconds = seconds % 60;
    const recordingMinutes = parseInt(seconds / 60);
    return `${
      recordingMinutes < 10 ? '0' + recordingMinutes : recordingMinutes
    }:${recordingSeconds < 10 ? '0' + recordingSeconds : recordingSeconds}${
      long ? (recordingMs < 10 ? ':0' + recordingMs : ':' + recordingMs) : ''
    }`;
  };

  const getLeftProgress = () => {
    return recordingTime !== 0 && lisetningTime !== 0
      ? lisetningTime < recordingTime
        ? `${(lisetningTime / recordingTime) * 97}%`
        : '100%'
      : 0;
  };

  const getPercentageProgress = () => {
    return recordingTime !== 0 && lisetningTime !== 0
      ? lisetningTime < recordingTime
        ? ((lisetningTime / recordingTime) * 97) / 100
        : 1
      : 0;
  };

  const playPause = () => {
    // button click function
    if (playing) {
      stop();
    } else {
      listen();
    }
  };

  const playerTick = () => {
    setListeningTime((lisetningTime) => lisetningTime + 3);
  };

  const listen = () => {
    if (audio && !uploading) {
      playing = true;
      setPlaying(true);
      audio.currentTime = 0;
      listeningTimerInterval = setInterval(playerTick, 30);
      audio.play();
    }
  };

  const resetAudio = () => {
    setAudio(null);
    mediaRecorder = null;
    audioBlob = null;
    audioUrl = null;
    setPlaying(false);
    setRecordingTime(0);
    setListeningTime(0);
    clearInterval(recordingTimerInterval);
    clearInterval(listeningTimerInterval);
    setIsSafari(null);
    setMaxRecordingTime(null);
  };

  const getAudio = () => {
    return audioUrl;
  };

  useEffect(() => {
    if (!window.MediaRecorder) {
      console.log('added audio-recorder-polyfill');
      window.MediaRecorder = require('audio-recorder-polyfill');
    }
  }, []);

  return {
    recordingTime,
    recordingState,
    playing,
    uploading,
    // methods
    playPause,
    send,
    remake,
    stopRecording,
    startRecording,
    getRecordingTime,
    getLeftProgress,
    getPercentageProgress,
    resetAudio,
    initFromUrl,
    getAudio,
  };
};
