import i18n from 'i18next';
import moment from 'moment';
import { useEffect, useState, useRef } from 'react';

import {
  API_ENDPOINTS,
  INTERACTION_API_TYPE_MAP,
} from '../../constants/common';
import { request } from '../utils/request';
import { WithDebounce } from '../utils/withDebounce';
import { prepareInteractionSave, processInteraction } from '../../utils/utils';
import { WithSteps } from '../steps/withSteps';
import { MIN_CHOICE_COUNT } from '../../constants/interactions/interaction';
import { WithChannel } from '../channels/withChannel';
import { cloneDeep } from 'lodash';

export const CREATE_INTERACTION_STEPS = [
  i18n.t('Choice of interaction'),
  i18n.t('Settings'),
  i18n.t('Availability'),
  i18n.t('Summary'),
];

export const GAME_TYPES = [
  { value: 'answer', label: i18n.t('Game with answer') },
  { value: 'registration', label: i18n.t('Game with a simple registration') },
];

export const SURVEY_TYPES = [
  { value: 'simple', label: i18n.t('Simple') },
  { value: 'voice', label: i18n.t('With voice participation') },
];

export const START_TYPES = [
  { value: 'now', label: i18n.t('Now') },
  { value: 'programmed', label: i18n.t('Programmed') },
];

export const END_TYPES = [
  { value: 'scheduled', label: i18n.t('Scheduled end') },
  { value: 'permanent', label: i18n.t('Permanent') },
];

export const AVAILABLE_INFORMATION = [
  { key: 'first_name', label: i18n.t('First name') },
  { key: 'name', label: i18n.t('Name') },
  { key: 'city', label: i18n.t('City') },
  { key: 'phone', label: i18n.t('Phone') },
  { key: 'email', label: i18n.t('Email') },
  { key: 'age', label: i18n.t('Age') },
];

const defaultInteraction = {
  choices: [],
  limit_1_vote: true,
  show_results: true,
  from: START_TYPES[0].value,
  start_date: moment(),
  end_date: moment().add(7, 'd'),
  to: END_TYPES[0].value,
  gather_information: true,
  information_to_gather: {},
  enable_written_answer: false,
  customisation: {},
  game_type: 'answer'
};

export const WithCreateInteraction = ({ initInteraction, channelId }) => {
  let [interaction, setInteraction] = useState(cloneDeep(defaultInteraction));
  const interactionRef = useRef(interaction);
  interactionRef.current = interaction;
  const [isSaved, setIsSaved] = useState(false);
  const [answerCount, setAnswerCount] = useState(MIN_CHOICE_COUNT);
  const [showColors, setShowColors] = useState(false);
  const { debounce } = WithDebounce();
  const {
    step,
    setStep,
    previousStep,
    nextStep: nextStepDefault,
    isStepValid,
    setIsStepValid,
  } = WithSteps();
  const { channel } = WithChannel({ channelId });

  const interactionInfoSave = async () => {
    const interaction = interactionRef.current;
    const body = prepareInteractionSave({ ...interaction });
    request({
      url: API_ENDPOINTS.segment(interaction.id),
      method: 'PUT',
      body,
    });
  };

  const interactionCreate = async () => {
    const body = prepareInteractionSave({ ...interactionRef.current });
    const { data } = await request({
      url: API_ENDPOINTS.segmentNew,
      method: 'PUT',
      body,
    });
    if (data.id) {
      const newInteraction = {
        ...interactionRef.current,
        id: data.id,
      };
      setInteraction(newInteraction);
      await interactionBackgroundSave();
    }
  };

  const interactionBackgroundSave = async () => {
    const interaction = interactionRef.current;
    if (interaction.background) {
      const formData = new FormData();
      if (interaction.background.type.split('/')[0]==='video'){
        formData.append('video', interaction.background);
      } else {
        formData.append('image', interaction.background);
      }
      const { data } = await request({
        url: API_ENDPOINTS.segmentBackgroundUpload(interaction.id),
        method: 'POST',
        formData,
      });

      const newInteraction = {
        ...interaction,
        ...processInteraction(data),
      };
      delete newInteraction.background;
      setInteraction(newInteraction);
    }
  };

  const saveInteraction = async () => {
    const interaction = interactionRef.current;
    if (!interaction.id) {
      await interactionCreate();
    } else {
      await interactionInfoSave();
      await interactionBackgroundSave();
    }
    setIsSaved(true);
  };

  const validateStep = () => {
    let isValid = false;
    if (step === 0) {
      isValid = !!interaction.type;
    } else if (step === 1) {
      isValid =
        interaction.title &&
        (interaction.type !== INTERACTION_API_TYPE_MAP.vote ||
          interaction.choices.length);
    } else if (step === 2) {
      isValid = interaction.start_date;
    }
    setIsStepValid(isValid);
  };

  const setField = (name, value) => {
    if (name.includes('|')) {
      const [key, index] = name.split('|');
      if (!interaction[key]) {
        interaction[key] = [];
      }
      interaction[key][index] = value;
      return setInteraction({ ...interaction });
    } else if (
      name === 'start_date' &&
      interaction.end_date &&
      value >= interaction.end_date
    ) {
      const newDateEnd = value.clone().add(1, 'hour');
      setField('end_date', newDateEnd);
    } else if (
      name === 'end_date' &&
      value &&
      value <= interaction.start_date
    ) {
      const newStartDate = value.clone().subtract(1, 'hour');
      setField('start_date', newStartDate);
    }
    setInteraction((prevInteraction) => ({
      ...prevInteraction,
      [name]: value,
    }));
    if (name !== 'type' && name !== 'testimonial_demanded') {
      debounce(saveInteraction);
    }
  };

  const setFrom = (name, value) => {
    if (value === 'now') {
      interaction[name] = value;
      setField('start_date', moment());
    } else {
      setField(name, value);
    }
  };

  const setTo = (name, value) => {
    interaction[name] = value;
    if (value === 'permanent') {
      setField('end_date', null);
    } else {
      setField('end_date', moment().add(7, 'd'));
    }
  };

  const nextStep = () => {
    if (!isStepValid) {
      return;
    }
    if (step === 2) {
      setField('status', 'ready');
    }
    nextStepDefault();
  };

  useEffect(validateStep, [interaction, step]);

  useEffect(() => {
    if (initInteraction) {
      setInteraction({
        ...cloneDeep(defaultInteraction),
        ...processInteraction(initInteraction),
      });
      setStep(1);
    }
  }, []);

  const removeChoiceOnIndex = (removeIndex) => {
    const newChoices = interaction.choices.filter(
      (choice, index) => index !== removeIndex
    );
    setField('choices', newChoices);
    setAnswerCount(
      newChoices.length >= MIN_CHOICE_COUNT
        ? newChoices.length
        : MIN_CHOICE_COUNT
    );
  };

  const addNewChoice = () => {
    setField(`choices|${answerCount + 1}`);
    setAnswerCount(answerCount + 1);
  };

  const resetCustomisation = () => {
    setInteraction((prevInteraction) => ({
      ...prevInteraction,
      customisation: channel.segment_customisation || {},
    }));
  };

  useEffect(() => {
    setTimeout(() => {
      if (!Object.keys(interactionRef.current.customisation).length) {
        resetCustomisation();
      }
    }, 1000);
  }, [channel]);

  return {
    isSaved,
    showColors,
    setShowColors,
    interaction,
    isStepValid,
    step,
    setField,
    nextStep,
    previousStep,
    interactionCreate,
    saveInteraction,
    setFrom,
    setTo,
    answerCount,
    removeChoiceOnIndex,
    addNewChoice,
    resetCustomisation,
  };
};
