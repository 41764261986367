import i18n from 'i18next';

import { COLOR } from '../common';

export const DEFAULT_COLOR = COLOR.grey9;

export const getLabel = (property) => {
  if (property.endsWith('_bg')) {
    return i18n.t('Background');
  }
  if (property.endsWith('_text')) {
    return i18n.t('Text');
  }
  if (property.endsWith('_border')) {
    return i18n.t('Border');
  }
  if (property.endsWith('_color')) {
    return i18n.t('Color');
  }
  return property;
};
