import React from 'react';

export const ArchiveIcon = () => (
  <svg
    height="21.5"
    viewBox="0 0 21.5 21.5"
    width="21.5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m22.489 5.478-1.545-1.867a1.613 1.613 0 0 0 -1.277-.611h-13.334a1.651 1.651 0 0 0 -1.289.611l-1.533 1.867a2.176 2.176 0 0 0 -.511 1.411v13.889a2.229 2.229 0 0 0 2.222 2.222h15.556a2.229 2.229 0 0 0 2.222-2.222v-13.889a2.176 2.176 0 0 0 -.511-1.411zm-9.489 13.633-6.111-6.111h3.889v-2.222h4.444v2.222h3.889zm-7.644-13.889.9-1.111h13.333l1.044 1.111z"
      fill="none"
      stroke="#a1a3a3"
      strokeWidth="1.5"
      className="active-change-stroke"
      transform="translate(-2.25 -2.25)"
    />
  </svg>
);
