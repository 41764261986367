import React from 'react';
import { COLOR } from '../../constants/common';

export const ChevronLeftIcon = ({ color = COLOR.black }) => (
  <svg
    enableBackground="new 0 0 24 24"
    viewBox="0 0 24 24"
  >
    <clipPath id="chevron-left">
      <path d="m-26.1-27h24v24h-24z" />
    </clipPath>
    <g fill="none">
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      >
        <g clipPath="url(#chevron-left)">
          <path d="m-21.3-7.8 7.2-14.4 7.2 14.5-7.2-3.5z" />
          <path d="m-14.1-11.5v-2.8" />
        </g>
        <path d="m13.4 5.7-6.4 6.3 6.4 6.3" strokeMiterlimit="10" />
      </g>
      <path d="m0 0h24v24h-24z" />
    </g>
  </svg>
);
