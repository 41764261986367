import React from 'react';
import { COLOR } from '../../constants/common';

export const LoaderIcon = ({ color = COLOR.white }) => (
  <svg height="26" width="26" xmlns="http://www.w3.org/2000/svg">
    <path d="m0 0h26v26h-26z" fill="none" />
    <g fill={color} transform="translate(-10.044 -1.4)">
      <path
        d="m23.423 315.7a6.279 6.279 0 0 1 -5.909-4.177h-1.926a8.111 8.111 0 0 0 15.67 0h-1.926a6.279 6.279 0 0 1 -5.909 4.177z"
        transform="translate(-.526 -294.89)"
      />
      <path d="m22.9 8.275a6.279 6.279 0 0 1 5.933 4.246h1.92a8.111 8.111 0 0 0 -15.706 0h1.92a6.279 6.279 0 0 1 5.933-4.246z" />
    </g>
  </svg>
);
