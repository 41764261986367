import styled, { css } from 'styled-components';
import { COLOR } from '../../constants/common';

export const TableWrapper = styled.div`
  overflow-y: auto;
`;

export const Table = styled.table`
  // Need to set height of the td to be able to use height 100% in div inside td bellow
  height: 1px;
  width: 100%;
  th,
  td {
    border-bottom: 1px solid ${COLOR.veryLightPink};
    &:first-child {
      padding-left: 27px;
    }
    &:last-child {
      padding-right: 27px;
    }
  }
`;

export const TableHeader = styled.thead``;

export const TableHeaderRow = styled.tr`
  height: 48px;
`;

export const TableHeaderCell = styled.th`
  text-align: left;
  font-weight: unset;
  padding: 7px 20px;
  &:first-letter {
    text-transform: capitalize;
  }
`;

export const TableBody = styled.tbody``;

export const Row = styled.tr`
  ${({ middle }) =>
    middle &&
    css`
      td {
        vertical-align: middle;
      }
    `};
`;

export const Cell = styled.td`
  padding: ${({ p }) => p || '7px 20px'};
  vertical-align: ${({ center }) => (center ? 'middle' : 'top')};
  height: 100%;
`;
