import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { Separator } from '../widget/Separator';
import { MainText } from '../widget/MainText';
import { Flex } from 'reflexbox';
import { Video } from '../../features/video/Video';
import { MP4_BACKGROUND_STR, STATIC_BUCKET } from '../../../constants/common';

const Wrapper = styled(Flex)`
  width: 256px;
  height: 437px;
  overflow: hidden;
  max-width: 100%;
  padding: 17px;
  background: ${({ background }) => background};
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  width: 100%;
  height: 222px;
  object-fit: cover;
  margin-bottom: 10px;
`;

const VideoPreview = styled(Video)`
  border-radius: 0;
  margin-top: 0;
  width: 100%;
  height: 222px;
  object-fit: cover;
  margin-bottom: 10px;
`

const ChannelImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-bottom: 17px;
  align-self: flex-start;
`;

export const WidgetPreview = ({
  backgroundMedia,
  channel,
  interactionText,
  customisation,
}) => (
  <Wrapper background={customisation.main_bg}>
    {channel.show_voxm_logo && <ChannelImage src={channel.logo_url} />}
    {backgroundMedia && backgroundMedia.includes(MP4_BACKGROUND_STR) && !backgroundMedia.includes(STATIC_BUCKET) ? (
        <VideoPreview src={backgroundMedia} autoPlay muted loop />
      ) : (
        <Image src={backgroundMedia || get(channel, 'background_url')} alt={''} />
    )}
    {interactionText ? (
      <>
        <Separator my={'10px'} color={customisation.main_text} />
        <MainText color={customisation.main_text}>{interactionText}</MainText>
        <Separator my={'10px'} color={customisation.main_text} />
      </>
    ) : null}
  </Wrapper>
);
