import React from 'react';
import { Flex } from 'reflexbox';
import styled from 'styled-components';
import { Text } from '../text/Text';
import { VoxMIcon } from '../icons/VoxMIcon';


const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
`

const Wrapper = styled(Flex)`
  position: absolute;
  bottom: 0;
  color: white;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 40px);
  
  svg {
    width: 52px;
    height: 17px;
    fill: white;
  }
`;

export const PoweredBy = ({ ...rest }) => (
  <Container>
    <Wrapper {...rest}>
      <Text color={rest['color']} theme={'white'} size={'xs'} mr={'7px'}>
        Powered by
      </Text>
      <VoxMIcon color={rest['color']}/>
    </Wrapper>
  </Container>
);
