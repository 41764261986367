import { useEffect, useRef, useState } from 'react';
import {
  API_ENDPOINTS,
  INTERACTION_API_TYPE_MAP,
  isMobile,
  isMobileRegex,
  VOICE_SHARE_URL,
} from '../../constants/common';
import {
  handlePartialUpload,
  initPartialUpload,
  request,
} from '../utils/request';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { useParams } from 'react-router-dom';
import { WithRecorder } from '../recorder/withRecorder';
import { useUser } from '../users/withUser';
import { share, uuid4 } from '../../utils/utils';
import { getValueForLanguage } from '../../features/translate/Translate';
import { get } from 'lodash';
import { WithVideoRecorder } from '../recorder/withVideoRecorder';
import moment from 'moment';
import { WithMessage } from '../messages/withMessage';

const CHECK_ACTIVE_INTERVAL = 1000 * 90;

export const WIDGET_SCREENS = {
  record: 'record',
  recording: 'recording',
  voting: 'voting',
  login: 'login',
  listen: 'listen',
  sending: 'sending',
  thanks: 'thanks',
  mySpace: 'mySpace',
  levels: 'levels',
  ranking: 'ranking',
  profileEdit: 'profileEdit',
  voteResults: 'voteResults',
};

export const WIDGET_INFORMATION_MAP = {
  first_name: 'First name',
  name: 'Name',
  city: 'City',
  phone: 'Phone',
  email: 'Email',
  age: 'Age',
};

export const WithWidget = () => {
  const [channel, setChannel] = useState();
  const [backgroundColor, setBackgroundColor] = useState('transparent');
  const [background, setBackground] = useState('');
  const [width, setWidth] = useState('100%');
  const [height, setHeight] = useState('100%');
  const [initialized, setInitialized] = useState(false);
  const [backgroundReady, setBackgroundReady] = useState(false);
  const [channelPermanents, setChannelPermanents] = useState([]);
  const [channelPermanentsLoaded, setChannelPermanentsLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sentInteraction, setSentInteraction] = useState();
  const [mainColor, setMainColor] = useState();
  const [selectedChoices, setSelectedChoices] = useState({});
  let [activeSegment, setActiveSegment] = useState();
  const activeSegmentRef = useRef(activeSegment);
  activeSegmentRef.current = activeSegment;
  const [activeScreen, setActiveScreen] = useState(WIDGET_SCREENS.record);
  const [
    initialLoginRegisterScreen,
    setInitialLoginRegisterScreen,
  ] = useState();
  const [hideAnonymous, setHideAnonymous] = useState(true);
  let [appUser, setAppUser] = useState();
  const [previousScreen, setPreviousScreen] = useState();
  const [confirmRecord, setConfirmRecord] = useState(false);
  const { search } = useLocation();
  const { channelId } = useParams();
  const { language: pageLanguage, setLanguage } = useUser();
  const isFacebook =
    navigator.userAgent.match(/FBAV|FBAN|FBIOS|FBDV|FBSN|FBID|FBSS/i) ||
    navigator.userAgent.includes('Instagram');
  const [isSocialMedia, setIsSocialMedia] = useState(false);
  const [sharedInteraction, setSharedInteraction] = useState();
  const [deviceId, setDeviceId] = useState();
  const [uploadProgress, setUploadProgress] = useState(0);
  const interactionDisabled = false;
  let fetchInterval = null;
  const [voteResultsTimeout, setVoteResultsTimeout] = useState();
  let [clicked, setClicked] = useState(false);
  const [alreadyReceived, setAlreadyReceived] = useState(false);
  const alreadyReceivedRef = useRef(alreadyReceived);
  alreadyReceivedRef.current = alreadyReceived;
  const [extraUserInformation, setExtraUserInformation] = useState({});
  const [showValidation, setShowValidation] = useState(false);
  const { fetchInteraction } = WithMessage({});

  const fetchUserPoints = async (channelId, appUser) => {
    if (!appUser || appUser.is_anonymous) {
      return;
    }
    const query = {
      channel_id: channelId,
      user_id: appUser.id,
    };
    const { data } = await request({
      url: API_ENDPOINTS.rankingUser,
      method: 'GET',
      query,
    });
    setAppUser((prev) => ({ ...prev, points: data }));
  };

  const setThanksScreen = () => {
    setActiveScreen(WIDGET_SCREENS.thanks);
    if (
      activeSegment &&
      (activeSegment.type === INTERACTION_API_TYPE_MAP.choice ||
        activeSegment.type === INTERACTION_API_TYPE_MAP.vote) &&
      (!activeSegment.testimonial_demanded || alreadyReceivedRef.current)
    ) {
      setVoteResultsTimeout(
        setTimeout(() => setActiveScreen(WIDGET_SCREENS.voteResults), 2000)
      );
    }
  };

  const setVoteScreen = (segment) => {
    setActiveSegment(segment);
    setActiveScreen(WIDGET_SCREENS.voting);
  };

  const manageChoice = (choice) => {
    if (!choice || !choice.id || !activeSegment) {
      return;
    }
    const { id } = choice;
    let newSelected = { ...selectedChoices };
    if (newSelected[id]) {
      // Already exists
      delete newSelected[id];
    } else {
      // Single and multi choice (multi should support only one)
      newSelected = { [id]: choice };
    }
    setSelectedChoices({ ...newSelected });
  };
  // For voice / video interaction
  const sendInteraction = async (file) => {
    if (
      activeSegment.registration_required &&
      (!appUser || appUser.is_anonymous)
    ) {
      setInitialLoginRegisterScreen(null);
      setPreviousScreen(WIDGET_SCREENS.recording);
      setHideAnonymous(activeSegment.registration_required);
      return setActiveScreen(WIDGET_SCREENS.login);
    }
    setActiveScreen(WIDGET_SCREENS.sending);
    setUploadProgress(1);
    const extension = file.name.split('.').pop();
    const { data_id, url } = await initPartialUpload(extension);

    await handlePartialUpload(file, url, setUploadProgress);

    const queryParams = parse(search);
    const query = {
      show_id: activeSegment.show_id,
      channel_id: activeSegment.channel_id,
      program_id: activeSegment.program_id,
      segment: activeSegment.id,
      language: pageLanguage,
      site_ref: queryParams.ref || null,
      author_app_user_id: appUser ? appUser.udid : null,
      device_id: deviceId,
      data_id,
      extension,
    };
    if (sentInteraction && sentInteraction.id) {
      query.update_interaction_id = sentInteraction.id;
    }
    const headers = {
      'Content-Disposition':
        'form-data; name="file"; filename="recording.webm"',
      'Content-Type': 'video/webm',
      'Extra-User-Information': JSON.stringify(extraUserInformation),
    };
    const promise = request({
      url: API_ENDPOINTS.interactions,
      method: 'PUT',
      query,
      headers,
    });
    resetAudio();
    resetVideo();
    setTimeout(() => {
      window.ga
        .getAll()[0]
        .send(
          'event',
          'Interaction ' + activeSegment.type,
          'sent',
          activeSegment.id + '',
          1
        );
    }, 0);
    const { data } = await promise;
    setTimeout(() => {
      window.ga
        .getAll()[0]
        .send(
          'event',
          'Interaction ' + activeSegment.type,
          'received',
          activeSegment.id + '',
          1
        );
    }, 0);
    setUploadProgress(0);
    setThanksScreen();
    if (data && data.id) {
      setSentInteraction(data);
    }
  };

  const shareInteraction = () => {
    if (!sentInteraction) {
      return;
    }
    setTimeout(() => {
      window.ga
        .getAll()[0]
        .send(
          'event',
          'Interaction ' + activeSegment.type,
          'shared',
          activeSegment.id + '',
          1
        );
    }, 0);
    share(
      `${VOICE_SHARE_URL}${sentInteraction.id}`,
      `${getValueForLanguage(pageLanguage, 'I have just reacted to')} ${
        channel.label
      } ${activeSegment ? activeSegment.title : ''}. ${getValueForLanguage(
        pageLanguage,
        'Here is my message :'
      )}`,
      pageLanguage
    );
  };

  const {
    recordingState,
    playing,
    uploading,
    playPause,
    send,
    remake,
    stopRecording,
    startRecording: startRecordingRecorder,
    getRecordingTime,
    getLeftProgress,
    resetAudio,
    initFromUrl,
  } = WithRecorder({
    onSend: sendInteraction,
    onError: () => setActiveScreen(WIDGET_SCREENS.record),
  });

  const {
    recordingState: recordingStateVideo,
    playing: playingVideo,
    uploading: uploadingVideo,
    playPause: playPauseVideo,
    send: sendVideo,
    remake: remakeVideo,
    stopRecording: stopRecordingVideo,
    startRecording: startRecordingRecorderVideo,
    getRecordingTime: getRecordingTimeVideo,
    getLeftProgress: getLeftProgressVideo,
    resetVideo,
    initFromUrl: initFromUrlVideo,
    uploadFromFile,
    uploadInit: uploadInitVideo,
  } = WithVideoRecorder({
    onSend: sendInteraction,
    onError: () => setActiveScreen(WIDGET_SCREENS.record),
  });

  const handleUploadFromFile = (e) => {
    setConfirmRecord(true);
    uploadFromFile(e);
    setActiveScreen(WIDGET_SCREENS.recording);
  };

  const handleRegisterSms = async (segment) => {
    if (activeSegment) {
      await sendOtherInteraction();
      return setThanksScreen(activeSegment);
    }
    setActiveSegment(segment);
    activeSegment = segment;
    if (segment.registration_required && (!appUser || appUser.is_anonymous)) {
      setInitialLoginRegisterScreen(null);
      setPreviousScreen(segment);
      setHideAnonymous(segment.registration_required);
      return setActiveScreen(WIDGET_SCREENS.login);
    }
  };

  const poolInteraction = async (taskId) => {
    const { data } = await request({
      url: API_ENDPOINTS.task(taskId),
      method: 'GET',
    });
    if (data.status === 'FAILURE') {
      return console.error(data);
    } else if (data.status === 'SUCCESS') {
      const interaction = await fetchInteraction(data.result);
      if (interaction.id) {
        setSentInteraction(interaction);
      }
    } else {
      setTimeout(() => poolInteraction(taskId), 2000);
    }
  };

  const sendVotes = async () => {
    const choice_ids = Object.keys(selectedChoices);
    if (choice_ids.length === 0) {
      return;
    }
    if (
      activeSegment.registration_required &&
      (!appUser || appUser.is_anonymous)
    ) {
      setInitialLoginRegisterScreen(null);
      setPreviousScreen(WIDGET_SCREENS.voting);
      setHideAnonymous(activeSegment.registration_required);
      return setActiveScreen(WIDGET_SCREENS.login);
    }
    setTimeout(() => {
      window.ga
        .getAll()[0]
        .send(
          'event',
          'Interaction ' + activeSegment.type,
          'sent',
          activeSegment.id + '',
          1
        );
    }, 0);
    setActiveScreen(WIDGET_SCREENS.sending);
    const { data } = await sendOtherInteraction({
      content: { choice_ids },
    });
    if (data.message) {
      setAlreadyReceived(true);
      setTimeout(() => {
        window.ga
          .getAll()[0]
          .send(
            'event',
            'Interaction ' + activeSegment.type,
            'received',
            activeSegment.id + '',
            1
          );
      }, 0);
    } else if (
      data.task_id &&
      activeSegment &&
      activeSegment.testimonial_demanded &&
      activeSegment.type === INTERACTION_API_TYPE_MAP.vote
    ) {
      poolInteraction(data.task_id);
    }
    setThanksScreen();
  };

  // For text/vote/sms interactions
  const sendOtherInteraction = async (data = {}) => {
    // Can't read activeSegment in setTimeout...
    if (!activeSegment && activeSegmentRef && activeSegmentRef.current) {
      activeSegment = activeSegmentRef.current;
    }
    if (
      activeSegment.registration_required &&
      (!appUser || appUser.is_anonymous)
    ) {
      setInitialLoginRegisterScreen(null);
      setPreviousScreen(WIDGET_SCREENS.record);
      setHideAnonymous(activeSegment.registration_required);
      return setActiveScreen(WIDGET_SCREENS.login);
    }
    const queryParams = parse(search);
    const body = {
      show_id: activeSegment.show_id,
      channel_id: activeSegment.channel_id,
      program_id: activeSegment.program_id,
      segment: activeSegment.id,
      site_ref: queryParams.ref || null,
      author_app_user_id: appUser ? appUser.udid : null,
      device_id: deviceId,
      extra_user_information: extraUserInformation,
      ...data,
    };
    return request({
      url: API_ENDPOINTS.interactions,
      method: 'POST',
      body,
    });
  };

  const fetchChannel = async (channelId) => {
    const { data } = await request({
      url: API_ENDPOINTS.channelDetailsWidget(channelId),
      method: 'GET',
    });
    setInitialized(true);
    setChannel(data);
    if (!data) {
      return;
    }
    setMainColor(data.primary_color ? data.primary_color : '#a37578');
  };

  const fetchPermanents = async () => {
    const queryParams = parse(search);
    let { data } = await request({
      url: `${API_ENDPOINTS.permanentAll(channelId)}${
        queryParams.from_perm ? '&from_perm=true' : ''
      }`,
      method: 'GET',
    });
    data = data.map((segment) => {
      const { gather_information, information_to_gather } = segment;
      const shouldAsk = gather_information && information_to_gather;
      if (!shouldAsk) {
        return segment;
      }
      const informationToGather = [];
      Object.entries(information_to_gather).forEach(([key, value]) => {
        if (value) {
          informationToGather.push(key);
        }
      });

      if (informationToGather.length) {
        segment.informationToGather = informationToGather;
      }

      return segment;
    });
    if (queryParams.interaction_id) {
      data = data.filter((segment) => segment.id == queryParams.interaction_id);
      if (data.length < 2 && isFacebook) {
        setActiveSegment(data[0]);
      }
    }
    setChannelPermanentsLoaded(true);
    setChannelPermanents(data);
  };

  const getBackground = () => {
    if (!initialized || !channelPermanentsLoaded) {
      return '';
    }
    let bgImage = '';
    if (
      channelPermanents[currentSlide] &&
      channelPermanents[currentSlide].background_url
    ) {
      bgImage = channelPermanents[currentSlide].background_url;
    }
    if (!bgImage) {
      bgImage = channel.background_url || channel.header_url || '';
    }
    setBackgroundReady(true);
    return bgImage;
  };

  const validateExtraInformation = (segment) => {
    if (!segment.informationToGather) {
      return true;
    }
    return (
      Object.values(extraUserInformation).filter((value) => value).length ===
      Object.keys(segment.informationToGather).length
    );
  };

  const startRecording = (segment, forceStart) => {
    if (interactionDisabled || isFacebook) {
      return;
    }
    setActiveScreen(WIDGET_SCREENS.record);
    setActiveSegment(segment);

    setTimeout(() => {
      window.ga
        .getAll()[0]
        .send(
          'event',
          'Interaction ' + segment.type,
          'start',
          segment.id + '',
          1
        );
    }, 0);

    if (segment.type === INTERACTION_API_TYPE_MAP.video) {
      if (activeSegment || clicked || channelPermanents.length < 2) {
        return;
      }
      clicked = true;
      setClicked(true);
      const galleryUploads = Array.from(
        document.getElementsByClassName(`gallery-upload_${segment.id}`)
      );
      if (galleryUploads[0]) {
        galleryUploads[0].click();
      }
      return;
    } else if (forceStart) {
      setConfirmRecord(true);
    } else if (!confirmRecord) {
      return setConfirmRecord(true);
    }
    if (segment.gather_information && !validateExtraInformation(segment)) {
      return setShowValidation(true);
    }
    setActiveScreen(WIDGET_SCREENS.recording);
    segment.type === INTERACTION_API_TYPE_MAP.video
      ? startRecordingRecorderVideo()
      : startRecordingRecorder(segment.max_recording_time);
  };

  const managePreviousScreen = () => {
    if (previousScreen) {
      // Segment for register / sms
      if (typeof previousScreen === 'object') {
        if (
          previousScreen.type === INTERACTION_API_TYPE_MAP.sms ||
          previousScreen.type === INTERACTION_API_TYPE_MAP.registration
        ) {
          setActiveScreen(WIDGET_SCREENS.record);
        } else {
          setThanksScreen(previousScreen);
        }
        setPreviousScreen(null);
        return;
      }
      setActiveScreen(previousScreen);
      if (previousScreen === WIDGET_SCREENS.voting) {
        sendVotes();
      }
      setPreviousScreen(null);
      return true;
    }
  };

  const handleAppUser = async (appUserData) => {
    await setAppUser(appUserData);
    // The above doesn't work inside sendInteraction
    appUser = appUserData;
    fetchUserPoints(channelId, appUserData);
    if (previousScreen && previousScreen !== WIDGET_SCREENS.record) {
      managePreviousScreen();
      if (recordingState === 'finished') {
        send();
      }
    } else {
      setPreviousScreen(null);
      setActiveScreen(WIDGET_SCREENS.mySpace);
    }
  };

  const setField = (name, value) =>
    setExtraUserInformation((prev) => ({ ...prev, [name]: value }));

  const handleClose = () => {
    clearTimeout(voteResultsTimeout);
    resetAudio();
    resetVideo();
    setConfirmRecord(false);
    setExtraUserInformation({});
    setActiveScreen(WIDGET_SCREENS.record);
    setActiveSegment(null);
    setSelectedChoices({});
    setSentInteraction(null);
    setAlreadyReceived(false);
  };

  const handleResetCamera = () => {
    resetVideo();
    setActiveScreen(WIDGET_SCREENS.record);
    startRecordingRecorderVideo(true);
  };

  const logout = () => {
    try {
      localStorage.removeItem('voxm_app_user');
    } catch (e) {}
    setAppUser(null);
  };

  const fetchSharedInteraction = async (sharedId) => {
    const { data } = await request({
      url: API_ENDPOINTS.interaction(sharedId),
      method: 'GET',
    });
    setSharedInteraction(data);
    const duration =
      moment.duration(get(data, 'content.duration')).asMilliseconds() / 10;
    const recordingUrl = get(data, 'content.recording_url');
    if (get(data, 'segment.type') === INTERACTION_API_TYPE_MAP.video) {
      initFromUrlVideo(recordingUrl, duration);
    } else {
      initFromUrl(recordingUrl, duration);
    }
  };

  const handleSlideChange = (slide) => {
    setCurrentSlide(slide);
    setClicked(false);
  };

  const clearShared = () => {
    resetAudio();
    resetVideo();
    setSharedInteraction(null);
  };

  useEffect(() => {
    fetchChannel(channelId);
    fetchPermanents();
    clearInterval(fetchInterval);
    fetchInterval = setInterval(() => {
      fetchPermanents();
    }, CHECK_ACTIVE_INTERVAL);
    let previousAppUser;
    try {
      previousAppUser = localStorage.getItem('voxm_app_user');
      const previousDeviceId = localStorage.getItem('device_id');
      if (previousDeviceId) {
        setDeviceId(previousDeviceId);
      } else {
        const newDeviceId = uuid4();
        setDeviceId(newDeviceId);
        localStorage.setItem('device_id', newDeviceId);
      }
    } catch (e) {
      setDeviceId(uuid4());
    }
    if (previousAppUser) {
      const appUserData = JSON.parse(previousAppUser);
      setAppUser(appUserData);
      fetchUserPoints(channelId, appUserData);
      setLanguage(appUserData.language);
      const queryParams = parse(search);
      if (queryParams.voxm_screen === 'space') {
        setActiveScreen(WIDGET_SCREENS.mySpace);
      } else if (queryParams.voxm_screen === 'profile') {
        setActiveScreen(WIDGET_SCREENS.profileEdit);
      }
    }
    return () => {
      clearInterval(fetchInterval);
    };
  }, []);

  useEffect(() => {
    const queryParams = parse(search);
    if (queryParams.width && !isMobile && !isMobileRegex) {
      setWidth(`${queryParams.width}px`);
    }
    if (queryParams.height && !isMobile && !isMobileRegex) {
      setHeight(`${queryParams.height}px`);
    }
    if (queryParams.background) {
      setBackgroundColor(queryParams.background);
    }
    if (queryParams.height && queryParams.width) {
      setIsSocialMedia(true);
    }
    // Shared interaction id
    if (queryParams.s) {
      setSharedInteraction(queryParams.s);
      fetchSharedInteraction(queryParams.s);
    }
  }, [search]);

  useEffect(() => {
    const newBackground = getBackground();
    setBackground(newBackground);
  }, [channel, channelPermanents, currentSlide]);

  const getSmsLink = (segment) => {
    if (!appUser || !segment || !segment.sms) {
      return '';
    }
    const re = /{([^}]+)?}/g;
    // let failed = false;
    // Replace {key} from string into user[key]
    const body = segment.sms.replace(re, (match, word) => {
      if (!appUser[word]) {
        // failed = word;
        return word;
      }
      return `${appUser[word]}`.trim();
    });
    const isIOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    const sep = isIOS ? '&' : '?';
    return `sms:${segment.receiver || ''}${sep}body=${encodeURIComponent(
      body
    )}`;
  };

  return {
    //Variables
    alreadyReceived,
    uploadProgress,
    isSocialMedia,
    recordingState,
    channel,
    appUser,
    hideAnonymous,
    playing,
    uploading,
    playPause,
    send,
    remake,
    stopRecording,
    getRecordingTime,
    getLeftProgress,
    recordingStateVideo,
    playingVideo,
    uploadingVideo,
    playPauseVideo,
    sendVideo,
    remakeVideo,
    stopRecordingVideo,
    getRecordingTimeVideo,
    getLeftProgressVideo,
    initFromUrlVideo,
    activeSegment,
    activeScreen,
    mainColor,
    confirmRecord,
    channelPermanents,
    channelPermanentsLoaded,
    initialized,
    width,
    height,
    backgroundColor,
    background,
    initialLoginRegisterScreen,
    isFacebook,
    sentInteraction,
    sharedInteraction,
    backgroundReady,
    uploadInitVideo,
    showValidation,
    extraUserInformation,
    //Setters
    setActiveScreen,
    setInitialLoginRegisterScreen,
    setHideAnonymous,
    setPreviousScreen,
    setActiveSegment,
    selectedChoices,
    setAppUser,
    setConfirmRecord,
    //  Methods
    handleSlideChange,
    logout,
    setField,
    shareInteraction,
    startRecording,
    handleClose,
    handleAppUser,
    managePreviousScreen,
    setThanksScreen,
    sendOtherInteraction,
    setVoteScreen,
    manageChoice,
    handleRegisterSms,
    sendVotes,
    getSmsLink,
    handleUploadFromFile,
    clearShared,
    handleResetCamera,
  };
};
