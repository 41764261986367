import styled, { css } from 'styled-components';
import { COLOR } from '../../constants/common';
import { Flex } from 'reflexbox';

export const Button = styled(Flex)`
  background: ${COLOR.yellowishOrange};
  border-radius: 5px;
  padding: 8px 14px;
  min-width: 90px !important;
  height: ${({ h }) => h || '35px'};
  align-items: center;
  justify-content: center;
  transition: background 50ms linear;
  cursor: pointer;
  color: white;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  box-sizing: border-box;
  text-decoration: none !important;

  ${({ big }) =>
    big &&
    css`
      min-width: 131px !important;
    `};

  ${({ theme }) =>
    theme === 'light' &&
    css`
      background: ${COLOR.white3};
    `};

  ${({ theme }) =>
    theme === 'white' &&
    css`
      background: ${COLOR.white};
      color: ${COLOR.brownishGrey};
      border: 1px solid ${COLOR.veryLightPink};
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background: ${COLOR.veryLightPink};
    `};

  &:active {
    background: white;
  }
`;
