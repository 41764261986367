import React, { lazy, Suspense } from 'react';
import { createGlobalStyle } from 'styled-components';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

// customizations
import './utils/styledComponents.withCustomProps';
import 'react-datetime/css/react-datetime.css';
import { globalFonts } from './constants/fonts';

// features
import { Layout } from './new/features/layout/Layout';

// contexts
import { UserProvider } from './models/users/withUser';
import { AlertProvider } from './models/global/withAlert';
import { GlobalProvider } from './models/global/withGlobal';
import { ModalProvider } from './new/models/global/withModal';

// screens
import { WidgetScreen as EmbedWidgetScreen } from './screens/widget/WidgetScreen';
import { WidgetScreen as EmbedWidgetScreenNew } from './new/screens/widget/WidgetScreen';
import QRCode from "react-qr-code";
import { FlexCenter } from './new/ui/box/FlexCenter';
import {Button} from "./new/ui/button/Button";
import {Flex} from "reflexbox/src";
import { downloadQrCode } from './new/features/interactions/Interactions';

const CreateInteractionScreen = lazy(() =>
  import('./screens/interactions/CreateInteractionScreen')
);
const ForgotPassword = lazy(() => import('./screens/login/ForgotPassword'));

// New
const InteractionsScreenNew = lazy(() =>
  import('./new/screens/interactions/InteractionsScreen')
);
const InteractionScreenNew = lazy(() =>
  import('./new/screens/interactions/InteractionScreen')
);
const ChannelScreenNew = lazy(() =>
  import('./new/screens/channels/ChannelScreen')
);
const UsersScreenNew = lazy(() => import('./new/screens/users/UsersScreen'));
const CustomisationScreen = lazy(() =>
  import('./new/screens/customisation/CustomisationScreen')
);
const UserScreenNew = lazy(() => import('./new/screens/users/UserScreen'));
const MessagesScreenNew = lazy(() =>
  import('./new/screens/messages/MessagesScreen')
);
const TimelineScreen = lazy(() =>
  import('./new/screens/timeline/TimelineScreen')
);
const DiffusionSpacesScreenNew = lazy(() =>
  import('./new/screens/diffusionSpaces/DiffusionSpacesScreen')
);
const DiffusionSpaceScreenNew = lazy(() =>
  import('./new/screens/diffusionSpaces/DiffusionSpaceScreen')
);
const ChannelsScreenNew = lazy(() =>
  import('./new/screens/channels/ChannelsScreen')
);
const LoginRegisterScreen = lazy(() =>
  import('./new/screens/login/LoginRegisterScreen')
);
const ConfirmRegisterScreen = lazy(() =>
  import('./new/screens/login/ConfirmRegisterScreen')
);
const ChangePasswordScreen = lazy(() =>
  import('./new/screens/login/ChangePasswordScreen')
);

const GlobalStyle = createGlobalStyle`
  ${globalFonts}
  body {
    background: white;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
  }
  input, textarea, button {
    outline: none;
    font-family: 'Montserrat', sans-serif;
  }
`;

const App = () => {
  return (
    <>
      <UserProvider>
        <GlobalProvider>
          <AlertProvider>
            <ModalProvider>
              <GlobalStyle />
              <Router>
                <Suspense fallback={<div />}>
                  <Switch>
                    <Route path={'/interactions/create'} exact>
                      <Layout>
                        <CreateInteractionScreen />
                      </Layout>
                    </Route>
                    <Route path={'/interactions/:id'} exact>
                      <Layout>
                        <InteractionScreenNew />
                      </Layout>
                    </Route>
                    <Route path={'/interactions'} exact>
                      <Layout>
                        <InteractionsScreenNew />
                      </Layout>
                    </Route>
                    <Route path={'/settings'} exact>
                      <Layout>
                        <ChannelScreenNew />
                      </Layout>
                    </Route>
                    <Route path={'/channels'} exact>
                      <Layout>
                        <ChannelsScreenNew />
                      </Layout>
                    </Route>
                    <Route path={'/channels/:id'} exact>
                      <Layout>
                        <ChannelScreenNew />
                      </Layout>
                    </Route>
                    <Route path={'/moderation'} exact>
                      <Layout>
                        <MessagesScreenNew isModeration />
                      </Layout>
                    </Route>
                    <Route path={'/participations/:id'} exact>
                      <Layout>
                        <MessagesScreenNew />
                      </Layout>
                    </Route>
                    <Route path={'/broadcast/:id'} exact>
                      <Layout>
                        <DiffusionSpaceScreenNew />
                      </Layout>
                    </Route>
                    <Route path={'/broadcast'} exact>
                      <Layout>
                        <DiffusionSpacesScreenNew />
                      </Layout>
                    </Route>
                    <Route path={'/users/:id'} exact>
                      <Layout>
                        <UserScreenNew />
                      </Layout>
                    </Route>
                    <Route path={'/users'} exact>
                      <Layout>
                        <UsersScreenNew />
                      </Layout>
                    </Route>
                    <Route path={'/customisation'} exact>
                      <Layout>
                        <CustomisationScreen />
                      </Layout>
                    </Route>
                    <Route path={'/timeline'} exact>
                      <Layout>
                        <TimelineScreen />
                      </Layout>
                    </Route>
                    <Route path={'/login'} exact>
                      <LoginRegisterScreen />
                    </Route>
                    <Route path={'/confirm/:token'} exact>
                      <ConfirmRegisterScreen />
                    </Route>
                    <Route path={'/reset/:token'} exact>
                      <ChangePasswordScreen />
                    </Route>
                    <Route path="/widgets/channel/:channelId/" exact>
                      <EmbedWidgetScreen />
                    </Route>
                    <Route path="/widgets/:channelId/:segmentId/" exact>
                      <EmbedWidgetScreenNew />
                    </Route>
                    <Route path="/forgot-password" exact>
                      <ForgotPassword />
                    </Route>
                    <Route path="/qr-code" exact>
                      <FlexCenter style={{ height: '100vh', flexDirection: 'column' }}>
                        <QRCode
                          size={512}
                          id={'qr-code'}
                          value={window.location.href.split("qrData=")[1]}
                        />
                        <Flex mt={'10px'}>
                          <Button onClick={() => downloadQrCode('qr-code', 'svg')}>
                            download QR code
                          </Button>
                        </Flex>
                        <Flex mt={'10px'}>
                          <Button onClick={() => downloadQrCode('qr-code', 'png')}>
                            Format PNG
                          </Button>
                        </Flex>
                      </FlexCenter>
                    </Route>
                    <Route>
                      <Redirect to={'/interactions'} />
                    </Route>
                  </Switch>
                </Suspense>
              </Router>
            </ModalProvider>
          </AlertProvider>
        </GlobalProvider>
      </UserProvider>
    </>
  );
};

export default App;
