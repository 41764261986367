import styled from 'styled-components';
import { COLOR } from '../../constants/common';

export const Separator = styled.div`
  height: 1px;
  width: 40%;
  max-width: 100px;
  box-shadow: 0 .4px 0 ${({ color }) => color || COLOR.grey7};
  margin: ${({ my }) => my || '30px'} 0;
  @media(max-height: 760px) {
    margin: 10px;
  }
`;
