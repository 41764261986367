import React from 'react';
import { COLOR } from '../../constants/common';

export const CheckmarkIcon = ({color=COLOR.white}) => (
  <svg
    height="26"
    width="26"
  >
    <clipPath id="checkmark">
      <path d="m0 0h16v16h-16z" />
    </clipPath>
    <g clipPath="url(#checkmark)" transform="translate(5 5)">
      <path
        d="m6.9 12-5.9-5.9 1.4-1.4 4.5 4.4 8.1-8.1 1.4 1.4z"
        fill={color}
        transform="translate(-1 1)"
      />
      <path d="m0 0h16v16h-16z" fill="none" />
    </g>
    <path d="m0 0h26v26h-26z" fill="none" />
  </svg>
);
